import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ContactForm from "../components/contact-form";
import Hero from "../components/hero";
import { purple } from "../util/colors";
import {
  Twitter,
  Instagram,
  Facebook,
  Email,
  Phone,
  WhatsApp,
  Website,
} from "../util/icons";

export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        contact {
          flyingColours {
            platform
            title
            url
          }
          dca {
            platform
            title
            url
          }
        }
      }
    }
    page: allSanityPageContact {
      nodes {
        title
        subtitle
        form {
          errorText
          intro
          reason
          title
          feedback {
            title
            thanks
            labelFeedback
          }
          parentMentor {
            title
            thanks
            labelQuestion
            labelName
            labelHearingLoss
            labelConsent
            labelComms
            labelAge
          }
        }
      }
    }
  }
`;

const Contact = ({ data, location }) => {
  const contactSections = data.site.siteMetadata.contact;
  const page = data.page.nodes[0];

  const icons = {
    twitter: <Twitter className="icon icon--inline" />,
    facebook: <Facebook className="icon icon--inline" />,
    instagram: <Instagram className="icon icon--inline" />,
    email: <Email className="icon icon--inline" />,
    phone: <Phone className="icon icon--inline" />,
    website: <Website className="icon icon--inline" />,
    whatsapp: <WhatsApp className="icon icon--inline" />,
  };

  return (
    <Layout>
      <SEO title={page.title} description={page.subtitle} />
      <Hero title={page.title} description={page.subtitle}></Hero>
      <div className="container container--column">
        <div className="contact-sections">
          {Object.entries(contactSections).map(section => {
            return (
              <div key={section[0]}>
                <h2>
                  {(() => {
                    switch (section[0]) {
                      case "social":
                        return "Find us on social";
                      case "dca":
                        return "Deaf Children Australia";
                      case "flyingColours":
                        return "Flying Colours";
                      default:
                        return "";
                    }
                  })()}
                </h2>
                <ul>
                  {section[1].map(item => {
                    return (
                      <li key={item.platform}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{ "--fill": purple }}
                        >
                          {icons[item.platform]}
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <ContactForm form={page.form} location={location} />
      </div>
    </Layout>
  );
};

export default Contact;
