import React from "react";
import Figure from "../components/blocks/figure";
import Video from "../components/blocks/video";
import PostTaxonomy from "../components/posts/taxonomy";
import { BackArrow } from "../util/icons";
const Hero = ({ title, description, poster, youtube, post, type }) => {
  let figure = "";
  const options = {
    width: 1200,
    placeholder: "blurred",
  };
  const aspectRatio = { w: 16, h: 9 };

  if (youtube) {
    figure = (
      <Video
        image={poster.image}
        options={options}
        youtube={youtube}
        aspectRatio={aspectRatio}
      ></Video>
    );
  } else if (poster) {
    figure = (
      <Figure
        figure={poster}
        options={options}
        aspectRatio={aspectRatio}
      ></Figure>
    );
  }

  const backButton = () => (
    <button
    className="button button--back"
      onClick={() => window.history.back()}
    >
     
        <BackArrow className="icon icon--inline"/>
         Back 
    </button>
  );
  return (
    <header className="hero">
      {type && backButton()}
      <div className="container container--column">
        {post && (
          <div className="hero-taxonomy">
            <PostTaxonomy post={post} clickable={true} />
          </div>
        )}
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="container">
        {figure && <div className="figure-container">{figure}</div>}
      </div>
    </header>
  );
};

export default Hero;
