/* global Rollbar */

const log = {
  info(e) {
    console.log(e);
    if (typeof Rollbar !== "undefined") {
      Rollbar.info(e);
    }
  },
  error(e) {
    console.error(e);
    if (typeof Rollbar !== "undefined") {
      Rollbar.error(e);
    }
  },
};

export default log;
