import React from "react";

const LoaderRing = ({ fill = "#fff" }) => (
  <div className="lds-ring" style={{ "--fill": fill }}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default LoaderRing;
