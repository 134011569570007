import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import log from "../util/log";
import LoaderRing from "./loader/ring";

const ContactForm = ({ form, location }) => {
  const [reason, setReason] = useState("");
  const [feedback, setFeedback] = useState("");
  const [hearingLoss, setHearingLoss] = useState("");
  const [age, setAge] = useState("");
  const [question, setQuestion] = useState("");
  const [name, setName] = useState("");
  const [contactMethod, setContactMethod] = useState("email");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [consent, setConsent] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [thanks, setThanks] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (location.hash === "#parent-mentor") {
      setReason("parentMentor");
    } else if (location.hash === "#feedback") {
      setReason("feedback");
    }
  });

  const formsparkIds = {
    feedback: "wllI2lbM",
    parentMentor: "QVwlSLo3",
  };

  const reset = () => {
    setFeedback("");
    setHearingLoss("");
    setAge("");
    setQuestion("");
    setName("");
    setContactMethod("email");
    setEmail("");
    setPhone("");
  };

  const submit = payload => {
    return new Promise((resolve, reject) => {
      if (reason) {
        const url = `https://submit-form.com/${formsparkIds[reason]}`;
        const method = "POST";
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const body = JSON.stringify(payload);

        setSubmitting(true);

        fetch(url, {
          method,
          headers,
          body,
        })
          .then(response => response.json())
          .then(json => {
            resolve(json);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    });
  };

  const onFormSubmit = async e => {
    e.preventDefault();

    let data;
    if (reason === "feedback") {
      data = {
        feedback,
      };
    } else if (reason === "parentMentor") {
      // Get contact details based on contact method
      let contact = "";
      switch (contactMethod) {
        case "email":
          contact = email;
          break;
        case "phone":
          contact = phone;
          break;
        default:
          break;
      }

      data = {
        name,
        age,
        hearingLoss,
        question,
        contactMethod,
        contact,
      };
    }

    if (data) {
      try {
        // Get ReCaptcha response
        const token = await recaptchaRef.current.executeAsync();

        // Submit data w/ ReCaptcha token
        await submit({
          ...data,
          ...{
            "g-recaptcha-response": token,
          },
        });
        setThanks(form[reason].thanks);
        reset();
      } catch (err) {
        log.error(err);
        setError(true);
      }
    } else {
      log.error("Invalid contact reason");
      setError(true);
    }
  };

  const recaptchaRef = React.createRef();

  const feedbackForm = (
    <div className="contact-form-feedback">
      <div className="form-field">
        <label htmlFor="feedback">
          {form.feedback.labelFeedback}
          <span className="required">(required)</span>
        </label>
        <textarea
          name="feedback"
          id="feedback"
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          required
          disabled={thanks !== null}
        ></textarea>
      </div>
    </div>
  );

  const parentMentorForm = (
    <div className="contact-form-parent-mentor">
      <div className="form-field">
        <label htmlFor="hearingLoss">
          {form.parentMentor.labelHearingLoss}
        </label>
        <select
          id="hearingLoss"
          name="hearingLoss"
          value={hearingLoss}
          onChange={e => setHearingLoss(e.target.value)}
          disabled={thanks !== null}
        >
          <option value="">-- Please choose an option --</option>
          <option value="Unilateral">Unilateral (one ear)</option>
          <option value="Bilateral">Bilateral (two ears)</option>
        </select>
      </div>
      <div className="form-field">
        <label htmlFor="age">{form.parentMentor.labelAge}</label>
        <select
          id="age"
          name="age"
          value={age}
          onChange={e => setAge(e.target.value)}
          disabled={thanks !== null}
        >
          <option value="">-- Please choose an option --</option>
          <option value="Under 1">Under 1</option>
          <option value="1-3">1 - 3</option>
          <option value="4-7">4 - 7</option>
          <option value="8-11">8 - 11</option>
          <option value="11+">11+</option>
        </select>
      </div>
      <div className="form-field">
        <label htmlFor="question">{form.parentMentor.labelQuestion}</label>
        <textarea
          name="question"
          id="question"
          value={question}
          onChange={e => setQuestion(e.target.value)}
          disabled={thanks !== null}
        ></textarea>
      </div>
      <div className="form-field">
        <label htmlFor="name">
          {form.parentMentor.labelName}
          <span className="required">(required)</span>
        </label>
        <input
          type="text"
          name="name"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
          disabled={thanks !== null}
        ></input>
      </div>
      <div className="form-field">
        <label htmlFor="contactMethod">
          {form.parentMentor.labelComms}
          <span className="required">(required)</span>
        </label>
        <select
          name="contactMethod"
          id="contactMethod"
          value={contactMethod}
          onChange={e => setContactMethod(e.target.value)}
          required
          disabled={thanks !== null}
        >
          <option value="email">Email</option>
          <option value="phone">Phone</option>
        </select>
      </div>
      <div className="form-field">
        {contactMethod === "email" && (
          <>
            <label htmlFor="email">
              Your email address
              <span className="required">(required)</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              disabled={thanks !== null}
            />
          </>
        )}
        {contactMethod === "phone" && (
          <>
            <label htmlFor="phone">
              Your phone number
              <span className="required">(required)</span>
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              required
              disabled={thanks !== null}
            />
          </>
        )}
      </div>
      <div className="form-field -checkbox">
        <div className="checkbox">
          <input
            type="checkbox"
            value={consent}
            name="consent"
            id="consent"
            onChange={e => setConsent(!consent)}
            disabled={thanks !== null}
          />
        </div>
        <label htmlFor="consent">
          {form.parentMentor.labelConsent}
          <span className="required">(required)</span>
        </label>
      </div>
    </div>
  );

  return (
    <div className="contact-form" id="feedback">
      <h2 id="parent-mentor">{form.title}</h2>
      <p>{form.intro}</p>
      <form onSubmit={onFormSubmit}>
        <div className="form-field">
          <label htmlFor="reason">
            {form.reason}
            <span className="required">(required)</span>
          </label>
          <select
            id="reason"
            name="reason"
            value={reason}
            onChange={e => {
              setReason(e.target.value);
              setThanks(null);
            }}
            disabled={thanks !== null}
          >
            <option value="">-- Please choose an option --</option>
            <option value="feedback">{form.feedback.title}</option>
            <option value="parentMentor">{form.parentMentor.title}</option>
          </select>
        </div>

        {reason === "feedback" && feedbackForm}

        {reason === "parentMentor" && parentMentorForm}

        {reason && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            badge="inline"
            sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
          />
        )}

        {reason && !thanks && (
          <div className="submit">
            <button
              type="submit"
              className={`button button--large -dosis ${
                submitting ? "-submitting" : ""
              }`}
              disabled={
                submitting || error || (reason === "parentMentor" && !consent)
              }
            >
              {submitting ? <LoaderRing /> : "Send"}
            </button>
          </div>
        )}

        {thanks && <div className="thanks">{thanks}</div>}

        {error && <div className="error">{form.errorText}</div>}
      </form>
    </div>
  );
};

export default ContactForm;
